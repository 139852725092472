import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

// custom components
import Header from "../components/Header";
import Banner from "../components/Banner";
import HomeGallery from "../components/HomeGallery";
import Footer from "../components/Footer";
import Seo from "../components/Seo";
import CallAction from "../components/CallAction";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "90%",
    margin: "0 auto",
    overflowX: "hidden",
  },
  section: {
    padding: 30,
  },
  title: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 30,
  },
  body: {
    margin: "25px 0px",
  },
  description: {
    marginTop: 30,
  },
  secCol: {
    display: "flex",
  },
  highlight: {
    padding: 10,
    background: "#eacc4e",
    marginBottom: 20,
    color: "#fff",
    fontSize: 25,
    marginRight: 10,
  },
  phone: {
    color: "#d2b641",
    fontWeight: "bold",
  },
  img: {
    width: 248,
    margin: "0 auto",
  },
}));

// markup
const IndexPage = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image_1: file(relativePath: { eq: "map_image.png" }) {
        childImageSharp {
          fluid(maxHeight: 148, maxWidth: 248) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={classes.root}>
      <Seo title='Dịch vụ khắc phục rò rỉ, Các mối nối mặt bích, Trên ống & các thành phần của ống, Trên các tuyến van' />

      {/** Header */}
      <Header currentPath='/' />

      {/** Main */}
      <main className={classes.main}>
        <Banner />

        {/** Section 1 */}
        <div name='service' className={classes.section} style={{ backgroundColor: "#f5efd3" }}>
          <Typography variant='h4' className={classes.title}>
            Dịch vụ khắc phục rò rỉ
          </Typography>
          <Typography variant='body1' align='center' className={classes.body}>
            Rò rỉ công nghiệp là một mối quan tâm hàng đầu với khách hàng của
            chúng tôi về an toàn, mất năng suất và môi trường. Một số rò rỉ quan
            trọng cũng có thể dẫn đến tắt máy nếu không có phương án thay thế kỹ
            thuật nào được cung cấp một cách nhanh chóng và an toàn. Đội ngũ
            dịch vụ của chúng tôi với nhiều năm kinh nghiệm xử lý tại các nhà
            máy lớn tại Việt Nam, các kỹ thuật viên được đào tạo bài bản xử lý
            nhanh trên toàn quốc, đảm bảo, thời gian phản ứng nhanh chóng.
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={4} xs={12} className={classes.secCol}>
              <span className={classes.highlight}>#1</span>
              <Typography variant='h5'>Các mối nối mặt bích</Typography>
            </Grid>

            <Grid item md={4} xs={12} className={classes.secCol}>
              <span className={classes.highlight}>#2</span>
              <Typography variant='h5'>
                Trên ống & các thành phần của ống
              </Typography>
            </Grid>

            <Grid item md={4} xs={12} className={classes.secCol}>
              <span className={classes.highlight}>#3</span>
              <Typography variant='h5'>Trên các tuyến van</Typography>
            </Grid>
          </Grid>
        </div>

        {/** Section 2 */}
        <div className={classes.section}>
          <Typography variant='h4' className={classes.title}>
            Khắc phục rò rỉ trên đường ống đang hoạt động
          </Typography>

          <Typography variant='h6' className={classes.step}>
            I. Tiến hành
          </Typography>

          <Typography variant='body1'>
            Ngày nay, việc khắc phục rò rỉ (leaking) khi hệ thống đường ống đang
            hoạt động là giải pháp thường được chọn vì nó không chỉ tiết kiệm
            chi phí khi có thể thi công mà không cần hệ thống phải dừng hoạt
            động mà còn giải quyết được hầu hết tất cả các loại rò rỉ trên đường
            ống. Các hợp chất mà chúng tôi sử dụng có thể thực hiện trong nhiều
            loại hệ thống đường ống từ hơi nước, hóa chất, các hợp chất gốc
            hydrocarbon, gas… và có thể thi công trong điều kiện nhiệt độ làm
            việc lên đến 500 °C, áp suất làm việc lên đến 350 bar. Phương pháp
            này đã được kiểm tra qua hàng ngàn ứng dụng trong nhiều năm nên hoàn
            toàn tin cậy để áp dụng tại hệ thống của Quý công ty. Hợp chất của
            chúng tôi được thiết kế, kiểm tra và sản xuất tuân theo tiêu chuẩn
            ISO 9001 và ISO 14001, do đó chất lượng sản phẩm được đảm bảo tối
            đa.
          </Typography>

          <Typography variant='body1' className={classes.bold}>
            Đội ngũ dịch vụ của chúng tôi đã được huấn luyện và hoàn thành nhiều
            dự án thuộc các ngành công nghiệp khác nhau nên có thể đảm bảo đáp
            ứng nhanh và khắc phục kịp thời các vấn đề của Quý khách hàng khi
            được yêu cầu.
          </Typography>

          <Typography variant='h6' className={classes.step}>
            II. Ứng dụng
          </Typography>

          <Typography variant='body1' className={classes.bold}>
            Những rò rỉ thông thường mà có thể làm kín khi hệ thống đang hoạt
            động:
          </Typography>
          <ul>
            <li>Rò rỉ tại các mối nối mặt bích</li>
            <li>Rò rỉ trên ống hoặc các thành phần của ống</li>
            <li>Rò rỉ trên các tuyến van</li>
          </ul>
          <Typography variant='body1' className={classes.bold}>
            Chúng tôi cũng thực hiện các giải pháp cho nhiều trường hợp rò rỉ
            chuyên biệt như:
          </Typography>
          <ul>
            <li>Rò rỉ trên máy biến áp</li>
            <li>Rò rỉ ở van bi</li>
            <li>Rò rỉ trên thân van (valve bonnets)</li>
            <li>Rò rỉ trên mái bồn</li>
            <li>Rò rỉ ở quạt làm mát</li>
          </ul>
          <Typography variant='body1'>
            Chúng tôi cũng có khả năng cung cấp các giải pháp tùy chỉnh thiết kế
            dựa trên các vấn đề của khách hàng.
          </Typography>
        </div>

        {/** Section 3 */}
        <div name='contact' className={classes.section}>
          <Typography variant='h3' className={classes.title}>
            Thông tin liên hệ
          </Typography>

          <Grid container spacing={0}>
            <Grid item md={4} xs={12}>
              <Img
                className={classes.img}
                fluid={data.image_1.childImageSharp.fluid}
              />
              <Typography variant='h5'>
                CÔNG TY TNHH DỊCH VỤ KỸ THUẬT OS
              </Typography>
              <Typography variant='body1'>
                Địa chỉ: 38E Trần Cao Vân, Phường 06, Quận 3, TP - HCM
              </Typography>
              <Typography variant='body1'>Mobile: 0937415072</Typography>
              <Typography variant='body1'>MST: 0315535383</Typography>
              <Typography variant='body1'>
                Web site: https://osts.com.vn
              </Typography>
              <Typography variant='body1'>
                Email: service@khacphucrori.com
              </Typography>
            </Grid>

            <Grid item md={8} xs={12}>
              <iframe
                title='map'
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.3632963137743!2d106.69401131526028!3d10.783462262011364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f3684cd6e21%3A0x7d9b8fd1859b3357!2zMzhlIFRy4bqnbiBDYW8gVsOibiwgUGjGsOG7nW5nIDYsIFF14bqtbiAzLCBI4buTIENow60gTWluaCwgVmlldG5hbQ!5e0!3m2!1sen!2s!4v1551974547033'
                width='100%'
                height='450'
                frameborder='0'
                style={{ border: 0 }}
              />
            </Grid>
          </Grid>
        </div>

        {/** Gallery */}
        <HomeGallery />
      </main>

      {/** Call action */}
      <CallAction />

      {/** Footer */}
      <Footer />
    </div>
  );
};

export default IndexPage;
